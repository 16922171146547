function productoModel (data) {
    this.id = data?.id;
    this.numserie = data?.numserie;
    this.modelo = data?.modelo;
    this.descripcion = data?.descripcion;
    this.marca = data?.marca;
    this.categoria = data?.categoria;
    this.coddigosat = data?.codigosat;
    this.precioCompra = data?.precio_compra;
    this.precioVenta = data?.precio_venta;
    this.entradas = data?.entradas;
    this.salidas = data?.salidas;
    this.stock = data?.stock;
}

export default productoModel;
