import React,{useState} from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import querys from '../services/querys';
import { useNavigate } from 'react-router-dom';

const EditUsuarios = ({ usuario }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(state => state.login.login);
  const [form,setForm] = useState({});
  const [checkbox,setCheckbox] = useState([]);

  const onChangeInput = ({ target }) => {
    const {name,value} = target;
    setForm({
      ...form,
      [name]:value
    })
  }

  const handleCheck = ({target}) => {
    const {value} = target;
    const validation = checkbox.some( item => item === value )
    if(validation) {
      const newArray = checkbox.filter(item => item !== value)
      setCheckbox(newArray);
    }else {
      setCheckbox([
        ...checkbox,
        value
      ])
    }
  }

  const handleUpdateUsuarios = (e) => {
    e.preventDefault();
    const body = {
      'id':usuario.id,
      'user':form.user,
      'nombre': form.name,
      'apellido': form.lastname, 
      'email': form.email,  
      'active': 'true', 
      "password":form.password,
      "permisos": checkbox, 
    }
    querys.updateUsuarios(body,dispatch,user.token).then(response => {
        navigate(`/dashboard`)
    })
}

  return (
    <form onSubmit={handleUpdateUsuarios} >
      <div className="Modal-formUsuario">
          <div className="Modal-formColumnUsuario">
              <label htmlFor="" >User:</label>
              <input type="text" placeholder={usuario.user} onChange={onChangeInput}  name='user'/>
              <label htmlFor="" >Nombre:</label>
              <input type="text" placeholder={usuario.name} onChange={onChangeInput}  name='name'/>
              <label htmlFor="" >Apellido:</label>
              <input type="text" placeholder={usuario.lastname} onChange={onChangeInput}  name='lastname'/>
              <label htmlFor="" >EMAIL:</label>
              <input type="text" placeholder={usuario.email} onChange={onChangeInput}  name='email'/>
              <label htmlFor="" >PASSWORD:</label>
              <input type="password" placeholder={usuario.password} onChange={onChangeInput}  name='password'/>
          </div>
          <div className='Modal-formColumnUsuario'>
            <div className="Modal-containerCheck">
              <label htmlFor="clientes">Clientes</label>
              <input type="checkbox" id="clientes" name="clientes"  onChange={handleCheck} value={1} className='Modal-checkbox'/>
            </div>
            <div className="Modal-containerCheck">  
              <label htmlFor="cobranza">Cobranza</label>
              <input type="checkbox" id="cobranza" name="cobranza" onChange={handleCheck} value={2} className='Modal-checkbox'/>
            </div>
            <div className="Modal-containerCheck">
              <label htmlFor="soporte">Soporte</label>
              <input type="checkbox" id="soporte" name="soporte" onChange={handleCheck} value={3} className='Modal-checkbox'/>
            </div>
            <div className="Modal-containerCheck"> 
              <label htmlFor="almacen">Almacen</label>
              <input type="checkbox" id="almacen" name="almacen" onChange={handleCheck} value={4} className='Modal-checkbox'/> 
            </div>
            <div className="Modal-containerCheck">
              <label htmlFor="usuarios">usuarios</label>
              <input type="checkbox" id="usuarios" name="usuarios" onChange={handleCheck} value={5} className='Modal-checkbox'/>  
            </div>
          </div>
      </div>
      <div className='Modal-containerBtn'>
        <input type="submit" value="Guardar" className='Modal-btnSave'/>
      </div>
    </form>
  )
}

export default EditUsuarios