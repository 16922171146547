import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice( { 
    name:'notification',
    initialState:{
        loading:false,
        
    },
    reducers: {
        notificationSuccess (state,action) {
            state.loading = true
        },
        notificationReset (state) {
            state.loading = false
            state.msg=""
        },
    }
})

export const {  notificationSuccess,notificationReset  } = notificationSlice.actions
export default notificationSlice.reducer;