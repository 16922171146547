import { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import querys from '../services/querys.js';
import { useDispatch } from 'react-redux';
import ModalUsuarios from '../components/ModalUsuarios.jsx';

const Usuarios = () => {
  const dispatch = useDispatch();
  const usuarios = useSelector( state => state.usuarios.usuarios.arrayUsuarios );
  const user = useSelector(state => state.login.login);
  const [modal,setModal] = useState(false);
  const [usuario,setUsuario] = useState();

  useEffect( () => {
    querys.getAllUsuarios(dispatch,user.token,1);  
  }, [])

  const handleModal = (usuario) => {
    setModal(!modal)
      setUsuario(usuario)   
}
  return (
    <div className='Clientes'>
      <div className="Usuarios-controles">
        <div className="Clientes-btnAdd" onClick={()=>handleModal()}><i className="fa-solid fa-plus"></i>Nuevo Registro</div>
      </div>
      <div className="Clientes-tablaContainer">
      <div className="Clientes-table">
          <div className="thead">
            <div className="th">Nombre</div>
            <div className="th">Apellidos</div>
            <div className="th">usuario</div>
            <div className="th">correo</div>
            <div className="th">Estatus</div>
          </div>
          <div className="tbody">
            { 
              usuarios && usuarios.length && usuarios.map( usuario => (
                <div className="row" key={ usuario.id }>
                  <div className="th" onClick={() => { handleModal( usuario ) }}>{ usuario.name }</div>
                  <div className="th" onClick={() => { handleModal( usuario ) }}>{ usuario.lastname }</div>
                  <div className="th" onClick={() => { handleModal( usuario ) }}>{ usuario.user }</div>
                  <div className="th" onClick={() => { handleModal( usuario ) }}>{ usuario.email }</div>
                  <div className="th" onClick={() => { handleModal( usuario ) }}>{ usuario.active }</div>
                </div>
              ) )
            }
          </div>
        </div>
      </div>
    <ModalUsuarios usuario={ usuario } modal={ modal } handleModal={handleModal}/>
  </div>
  )
}

export default Usuarios