import { createBrowserRouter } from "react-router-dom";
import Almacen from "../routes/Almacen";
import Clientes from "../routes/Clientes";
import Cobranza from "../routes/Cobranza";
import Dashboard from "../routes/Dashboard";
import Home from "../routes/Home";
import Login from "../routes/Login";
import NotFound from "../routes/NotFound";
import Soporte from "../routes/Soporte";
import Usuarios from "../routes/Usuarios";

export const router = createBrowserRouter ([
    {
        path:"/",
        element:<Login/>,
        errorElement: <NotFound/>,
    },
    {
        path:"/dashboard",
        element:<Dashboard/>,
        errorElement: <NotFound/>,
        children: [
            {
                index:true,
                element:<Home/>,
            },
            {
                path:"clientes",
                element:<Clientes/>
            },
            {
                path:"cobranza",
                element:<Cobranza/>
            },
            {
                path:"soporte",
                element:<Soporte/>
            },
            {
                path:"almacen",
                element:<Almacen/>
          },
          {
            path:"usuarios",
            element:<Usuarios/>
      }
        ]
    }
])