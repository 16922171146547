import React,{useState,useEffect} from 'react'
import Aside from '../components/Aside'
import Header from '../components/Header'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Notificacion from '../components/Notificacion';

const Dashboard = () => {
const login = useSelector(state => state.login.login)
const navigate = useNavigate();

  useEffect(() => {
    if (login === false) {
      navigate(`/`)
    }
  }, [login])
  

  const [menu,setClose] = useState(false);
  const [title,setTitle] = useState("")
  
  const handleMenu = () => {
    setClose(!menu)
  }
  const handleTitle = (titulo) => {
    setTitle(titulo)
  }

  return (
    <div className='Dashboard'>
        <Aside handleMenu={handleMenu} menu={menu} handleTitle={handleTitle}/>
        <div className="Dashboard-main">
          <Header handleMenu={handleMenu} title={title}/>
          <div className='dashboard-container'>
            <Outlet/>
          </div>
        </div>
    </div>
  )
}

export default Dashboard