function ticketModel (data) {
    this.id = data?.id;
    this.idcliente = data?.idcliente;
    this.msg = data?.msg;
    this.responsableid = data?.responsableid;
    this.estatus = data?.estatus;
    this.date = data?.date;
    this.responsableName = data?.responsablenombre;
    this.nombre = data?.nombre;
    this.telefono = data?.telefono;
    this.email = data?.email;
}
export default ticketModel;