import EditCustomer from "./EditCustomer"
import NewCustomer from "./NewCustomer"


const ModalClientes = ({ modal,handleModal,customer }) => {
    
  return (
    <div className={modal ? 'Modal' : 'Modal-hidden'}>
        <div className="Modal-container">
            <div className="Modal-title">
                <h3>{customer ? 'Modificar Cliente' : 'Agregar Cliente'}</h3>
                <div className="Modal-close" onClick={handleModal}>X</div>
            </div>
            <div className="Modal-form">
              {
                customer ? 
                <EditCustomer customer={customer}/>
                :
                <NewCustomer/>
              }
            </div>
        </div>
    </div>
  )
}

export default ModalClientes