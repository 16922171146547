import React from 'react'
import { useRouteError,Link} from 'react-router-dom'

const NotFound = () => {
  const error = useRouteError();

  return (
    <div>
      <h1>Sitio no encontrado</h1>
      <p>Page not found</p>
      <p>{error.statusText || error.message}</p>
      <Link to="/dashboard">Regresar al home</Link>
    </div>
  )
}

export default NotFound