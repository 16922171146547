import React from 'react';
import { Document, Page, View, Text , Image } from '@react-pdf/renderer'
import logo from '../assets/logo.png'
import Moment from 'moment'

const responsivapdf = ({pdfDocumentData}) => {
  console.log(pdfDocumentData)
  return (
    <Document>
      <Page size="A4">
        <View
          style={{
            display:'flex',
            flexDirection: 'row',
            alignItems:'center',
            padding:'0 50px',
            justifyContent:'space-between'
          }}
        >
          <View 
            style={{
              width: '20%'
            }}
          >
            <Image src={logo}/>
          </View>
          <View
            style={{
              display:'flex',
              flexDirection:'column',
              justifyContent:'flex-end',
              alignItems:'flex-end',
            }}
          >
            <Text>Acta de entrega de equipo</Text>
            <Text>Fecha: {Moment(pdfDocumentData.responsiva.fecha).format('DD/MM/YYYY')}</Text>
          </View>
        </View>
        <View
          style={{
            display:'flex',
            flexDirection: 'column',
            padding:'0 50px',
          }}
        >
        <View>
          <Text
          style={{
            fontSize:'12px',
            marginBottom:'10px'
          }}
          >
            Por medio de la presente se hace entrega a {pdfDocumentData.responsiva.nombreresponsable} {pdfDocumentData.responsiva.apellidoresponsable} del siguiente equipo
          </Text>
        </View>
        <View>
          <View
            style={{
              display:'flex',
              flexDirection:'row',
              fontSize:'12px',
              border: '1px solid black'
            }}
          >
            <View
              style={{
                width:'20%',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderLeft: '1px solid black'
              }}
            >
              <Text>N° de serie</Text></View>
            <View
              style={{
                width:'20%',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderLeft: '1px solid black'
              }}
            >
              <Text>Modelo</Text></View>
            <View
              style={{
                width:'20%',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderLeft: '1px solid black'
              }}
            >
              <Text>Marca</Text></View>
            <View
              style={{
                width:'20%',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderLeft: '1px solid black'
              }}
            >
              <Text>Descripcion</Text></View>
            <View
              style={{
                width:'20%',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderLeft: '1px solid black'
              }}
            >
              <Text>Categoria</Text></View>
            <View
              style={{
                width:'20%',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderLeft: '1px solid black'
              }}
            ><Text>Cantidad</Text></View>
          </View>
          {pdfDocumentData.productos.map(producto => {
            return(
            <View
              style={{
                display:'flex',
                flexDirection:'row',
                fontSize:'12px',
                border: '1px solid black'
              }}
            >
            <View
              style={{
                width:'20%',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderLeft: '1px solid black'
              }}
            >
              <Text>{producto.numserie}</Text></View>
            <View
              style={{
                width:'20%',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderLeft: '1px solid black'
              }}
            >
              <Text>{producto.modelo}</Text></View>
            <View
              style={{
                width:'20%',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderLeft: '1px solid black'
              }}
            >
              <Text>{producto.marca}</Text></View>
            <View
              style={{
                width:'20%',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderLeft: '1px solid black'
              }}
            >
              <Text>{producto.descripcion}</Text></View>
            <View
              style={{
                width:'20%',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderLeft: '1px solid black'
              }}
            >
              <Text>{producto.categoria}</Text></View>
            <View
              style={{
                width:'20%',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                borderLeft: '1px solid black'
              }}
            ><Text>{producto.cantidad}</Text></View>
          </View>
            )
          })}
        </View>
        <View
          style={{
            display:'flex',
            flexDirection:'row',
            justifyContent:'space-between',
            fontSize:'12px',
            marginTop:'60px',
          }}
        >
          <View
            style={{
              width:'40%',
              borderTop:'1px solid black',
              textAlign:'center'
            }}
          >
            <Text>Firma de entrega</Text>
            <Text>
              {pdfDocumentData.responsiva.nombreuser} {pdfDocumentData.responsiva.apellidouser}
            </Text>
          </View>
          <View
            style={{
              width:'40%',
              borderTop:'1px solid black',
              textAlign:'center'
            }}
          >
            <Text>Firma de recibido</Text>
            <Text>
              {pdfDocumentData.responsiva.nombreresponsable} {pdfDocumentData.responsiva.apellidoresponsable}
            </Text>
          </View>
        </View>
        </View>  
      </Page>
    </Document>
  );
};

export default responsivapdf;