import React, { useState } from 'react';

const Search = ({ array,funcion, itemViews, name, title, addType, isRequired, placeholderSearch, funcionRemove }) => {
  const [inputSearch , setInputSearch] = useState('');
  const [resultInput, setResultInput] = useState([])
  const [activeDelete, setActiveDelete] = useState(false)

  const handleSearh = (e) => {
    setInputSearch(e.target.value)
    filterSearch(e.target.value)
  } 

  const filterSearch = (param) => {
    const resultadoSearch = array.filter(item => {
      const objettoArray = Object.values(item)
      let stringConcat = '';
      for (let index = 0; index < objettoArray.length; index++) {
        if (objettoArray[index]!==undefined && objettoArray[index]) {
          stringConcat = stringConcat + ' ' + objettoArray[index].toString().toLowerCase()
        } 
      }
      if (stringConcat.toString().toLowerCase().includes(param.toLowerCase())) {
        return item
      }    
    });
    setResultInput(param !== '' ? resultadoSearch : [])
  } 

  const renderItems = (itemResult) => {
    let stringerData = ''; 
    for (let index = 0; index < itemViews.length; index++) {
      stringerData = stringerData + ' ' + itemResult[itemViews[index]]
    }
    return stringerData
  }

  const selectResult = (itemResult) => {
    let stringerData = ''; 
    for (let index = 0; index < itemViews.length; index++) {
      stringerData = stringerData + ' ' + itemResult[itemViews[index]]
    }
    funcion(name, itemResult)
    if (addType) {
      setInputSearch('')
    } else {
      setInputSearch(stringerData)
      setActiveDelete(true);
    }
    setResultInput([])
  }

  const handleRemove = () => {
    setInputSearch('')
    setResultInput([])
    setActiveDelete(false);
    if (funcionRemove) {
      funcionRemove()
    }
  }

  return (
    <div className='almacen-responsable'>
      <div>
        <label htmlFor="">{ title }</label>
        <div className='inputTicketsDetails inputSelectAlmacen'>
          <input 
            type="text" 
            onChange={ handleSearh } 
            value={ inputSearch } 
            required={ isRequired }
            placeholder={ placeholderSearch ? placeholderSearch : '' }
          />
          { activeDelete && <div onClick={() => handleRemove() } className='removeItem'><span>x</span></div> }
          {resultInput.length > 0 && <div className='inputSelectAlmacen-containerSearch'>
            { 
              resultInput.map(result=> { return(
              <div onClick={() => selectResult(result)}>
                {
                  renderItems(result)
                }
              </div>)})
            }
          </div> }
        </div>
      </div>
    </div>
  );
};

export default Search;