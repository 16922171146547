import axios from 'axios'
import {  loginSuccess,loginError,loginLoading } from '../redux/reducers/loginSlice'
import {  customersSuccess,customersError,customersLoading } from '../redux/reducers/customersSlice'
import { customerSuccess,customerError,customerLoading } from '../redux/reducers/customerSlice';
import { usuariosSuccess,usuariosError,usuariosLoading } from '../redux/reducers/usuariosSlice';
import { ticketsSuccess,ticketsError,ticketsLoading } from '../redux/reducers/ticketsSlice';
import { categoriasSuccess,categoriasError,categoriasLoading } from '../redux/reducers/categoriasSlice';
import { usuarioSuccess,usuarioError,usuarioLoading } from '../redux/reducers/usuarioSlice';
import userModel from '../models/userModel'
import customersModel from '../models/customersModel'
import usuariosModel from '../models/usuariosModel';
import allUsuariosModel from '../models/allUsuariosModel'
import ticketsModel from '../models/ticketsModel'
import ticketModel from '../models/ticketModel'
import categoriasModel from '../models/categoriasModel'
import prodductosModel from '../models/productosModel'
import allProductsModel from '../models/allProductosModel'
import responsivaModel from '../models/responsivamodel';

const baseUrlApi = 'https://api.regatelecom.mx';
const querys = {
    async postLogin ( body,dispatch ) {
        return await postApi(
            baseUrlApi+'/user/sigin',
            dispatch,
            body,
            userModel, 
            loginSuccess,
            loginError,
            loginLoading,
            null,
            'POST'
          )
    },
    async getAllUsuarios (dispatch,token,id) {
      return await postApi(
        baseUrlApi+`/user/getallusers/${id}`,
        dispatch,
        null,
        usuariosModel, 
        usuariosSuccess,
        usuariosError,
        usuariosLoading,
        {
          'Authorization':'Bearer ' + token
        },
        'GET'
      )
    },
    async getUsuarios (dispatch,token) {
      return await postApi(
        baseUrlApi+`/user/getallusers`,
        dispatch,
        null,
        allUsuariosModel, 
        usuariosSuccess,
        usuariosError,
        usuariosLoading,
        {
          'Authorization':'Bearer ' + token
        },
        'GET'
      )
    },
    async newUser ( body,dispatch,token ) {
      return await postApi(
          baseUrlApi+'/user/adduser',
          dispatch,
          body,
          usuariosModel, 
          usuarioSuccess,
          usuarioError,
          usuarioLoading, 
          {
            'Authorization':'Bearer ' + token
          },
          'POST'
        )
  },async updateUsuarios(body,dispatch,token){
      return await postApi(
        baseUrlApi+'/user/update',
        dispatch,
        body,
        usuariosModel, 
        usuarioSuccess,
        usuarioError,
        usuarioLoading, 
        {
          'Authorization':'Bearer ' + token
        },
        'PUT'
      )
  },
    async getAllCustomers (dispatch,token,id) {
      return await postApi(
        baseUrlApi+`/profile/getallusers/${id}`,
        dispatch,
        null,
        customersModel, 
        customersSuccess,
        customersError,
        customersLoading,
        {
          'Authorization':'Bearer ' + token
        },
        'GET'
      )
    }, 
    async getCustomerSearchById ( body,dispatch,token ) {
      return await postApi(
          baseUrlApi+'/profile/searchuser',
          dispatch,
          body,
          customersModel, 
          customerSuccess,
          customerError,
          customerLoading,
          {
            'Authorization':'Bearer ' + token
          },
          'POST'
        )
  },
    async newCustomer ( body,dispatch,token ) {
      return await postApi(
          baseUrlApi+'/profile/adduser',
          dispatch,
          body,
          userModel, 
          customerSuccess,
          customerError,
          customerLoading,
          {
            'Authorization':'Bearer ' + token
          },
          'POST'
        )
  },
  async updateCustomer ( body,dispatch,token ) {
    return await postApi(
        baseUrlApi+'/profile/update',
        dispatch,
        body,
        userModel, 
        customerSuccess,
        customerError,
        customerLoading,
        {
          'Authorization':'Bearer ' + token
        },
        'PUT'
      )
  },
  async getAllTickets (dispatch,pag) {
    return await postApi(
      baseUrlApi+`/support/ticket/all/${pag}`,
      dispatch,
      null,
      ticketsModel, 
      ticketsSuccess,
      ticketsError,
      ticketsLoading,
      null,
      'GET'
    )
  }, 
  async getSearchTickets (dispatch,id) {
    return await postApi(
      baseUrlApi+`/support/ticket/search/${id}`,
      dispatch,
      null,
      ticketModel, 
      null,
      ticketsError,
      ticketsLoading,
      null,
      'GET'
    )
  }, 
  async getCategorias (dispatch,token) {
    return await postApi(
      baseUrlApi+`/almacen/categorias/`,
      dispatch,
      null,
      categoriasModel, 
      null,
      categoriasError,
      categoriasLoading,
      {
        'Authorization':'Bearer ' + token
      },
      'GET'
    )
  }, 
  async addCategorias ( body,dispatch, token ) {
    return await postApi(
        baseUrlApi+`/almacen/categorias/`,
        dispatch,
        body,
        categoriasModel, 
        null,
        categoriasError,
        categoriasLoading,
        {
          'Authorization':'Bearer ' + token
        },
        'POST'
      )
  },
  async getMarcas (dispatch,token) {
    return await postApi(
      baseUrlApi+`/almacen/marcas/`,
      dispatch,
      null,
      categoriasModel, 
      null,
      categoriasError,
      categoriasLoading,
      {
        'Authorization':'Bearer ' + token
      },
      'GET'
    )
  }, 
  async addMarcas ( body,dispatch, token ) {
    return await postApi(
        baseUrlApi+`/almacen/marcas/`,
        dispatch,
        body,
        categoriasModel, 
        null,
        categoriasError,
        categoriasLoading,
        {
          'Authorization':'Bearer ' + token
        },
        'POST'
      )
  },
  async getAllProducts (dispatch,token,pag) {
    return await postApi(
      baseUrlApi+`/almacen/productos/${pag}`,
      dispatch,
      null,
      prodductosModel, 
      null,
      ticketsError,
      ticketsLoading,
      {
        'Authorization':'Bearer ' + token
      },
      'GET'
    )
  }, 
  async getProducts (dispatch,token) {
    return await postApi(
      baseUrlApi+`/almacen/productos`,
      dispatch,
      null,
      allProductsModel, 
      null,
      ticketsError,
      ticketsLoading,
      {
        'Authorization':'Bearer ' + token
      },
      'GET'
    )
  },
  async addResponsiva ( body,dispatch, token ) {
    return await postApi(
        baseUrlApi+`/almacen/responsiva/`,
        dispatch,
        body,
        responsivaModel, 
        null,
        categoriasError,
        categoriasLoading,
        {
          'Authorization':'Bearer ' + token
        },
        'POST'
      )
  },
}

const postApi = async (url,dispatch,body,modelApi,action,actionError,actionLoading,headers,method) => {
    dispatch(actionLoading())
    const options = {
      method,
      headers,
      url,
      data:body
    }
    const result = await axios(options)
    .then(function(response){
      console.log(response)
      const data = new modelApi(response.data)
      if(action){dispatch(action(data))}
      return data
    }).catch(function(error){
      dispatch(actionError(error.response?.data))
      return Promise.reject(error.response?.data)
    });
    return result
  }

  export default querys;