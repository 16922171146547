import React from 'react'
import EditUsuarios from './EditUsuarios'
import NewUsuario from './NewUsuario'

const ModalUsuarios = ({ modal,handleModal,usuario }) => {
  return (
    <div className={modal ? 'Modal' : 'Modal-hidden'}>
    <div className="Modal-container">
        <div className="Modal-title">
            <h3>{usuario ? 'Modificar Cliente' : 'Agregar Cliente'}</h3>
            <div className="Modal-close" onClick={handleModal}>X</div>
        </div>
        <div className="Modal-form">
            {
                usuario ? 
                <EditUsuarios usuario={usuario}/>
                :
                <NewUsuario/>
            }
         
         
        </div>
    </div>
</div>
  )
}

export default ModalUsuarios