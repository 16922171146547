import React,{useState} from 'react'
import ModalCobranza from '../components/ModalCobranza';

const Cobranza = () => {
  const [modal,setModal] = useState(false);
  
  const search = () => {
    console.log('buscamos');
  }

  const clean = () => {
    console.log('limpiamos');
  }

  const placeholder = 'Buscar';

  const onChangeInput = () => {
    console.log('change')
  }

  const handleModal = () => {
    setModal(!modal)
  }

  return (
    <div className='Cobranza'>
      <div className="Clientes-controles Cobranza-controles">
        <h3 className='Cobranza-h3'>Registro de Pagos</h3>
          <button type='submit' className="Clientes-btnFind" onClick={()=>handleModal()}><i class="fa-solid fa-plus"></i>Registrar Pago</button>
      </div>
      <div className="Clientes-tablaContainer">
      <div className="Clientes-table">
          <div className="thead">
            <div className="th">No contrato</div>
            <div className="th">Nombre</div>
            <div className="th">Apellidos</div>
            <div className="th">fecha de pago</div>
            <div className="th">Monto Pagado</div>
            <div className="th">Plan</div>
            <div className="th">Usuario</div>
          </div>
          <div className="tbody">
                <div className="row">
                  <div className="th">0002</div>
                  <div className="th">Noe</div>
                  <div className="th">Aviles</div>
                  <div className="th">22/06/2023</div>
                  <div className="th">$500.00</div>
                  <div className="th">HOGAR SOFT 5</div>
                  <div className="th">Mariana Ibarra</div>
                </div>
                <div className="row">
                  <div className="th">0010</div>
                  <div className="th">Juan</div>
                  <div className="th">Guzman</div>
                  <div className="th">02/06/2023</div>
                  <div className="th">$1000.00</div>
                  <div className="th">HOGAR START 10</div>
                  <div className="th">Mariana Ibarra</div>
                </div>
                <div className="row">
                  <div className="th">0004</div>
                  <div className="th">Teresa</div>
                  <div className="th">Herrera</div>
                  <div className="th">02/06/2023</div>
                  <div className="th">$3200.00</div>
                  <div className="th">HOGAR PREMIUM 40</div>
                  <div className="th">Javier Varela</div>
                </div>
                <div className="row">
                  <div className="th">00103</div>
                  <div className="th">Paulina</div>
                  <div className="th">Ochoa</div>
                  <div className="th">09/06/2023</div>
                  <div className="th">$2000.00</div>
                  <div className="th">HOGAR PLUS 20</div>
                  <div className="th">Javier Varela</div>
                </div>
                <div className="row">
                  <div className="th">00020</div>
                  <div className="th">Raul</div>
                  <div className="th">Robles</div>
                  <div className="th">02/06/2023</div>
                  <div className="th">$3200.00</div>
                  <div className="th">HOGAR PREMIUM 40</div>
                  <div className="th">Mariana Ibarra</div>
                </div>
          </div>
        </div>
      </div>
      <ModalCobranza modal={modal} handleModal={handleModal}/>
    </div>
  )
}

export default Cobranza