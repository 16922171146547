import { createSlice } from "@reduxjs/toolkit";

const ticketsSlice = createSlice({
    name:'tickets',
    initialState:{
        loading:false,
        tickets:[],
        error:false
    },
    reducers: {
        ticketsSuccess (state,action) {
            state.loading = false
            state.tickets=action.payload
            state.error = false
        },
        ticketsError (state,action) {
            state.loading = false
            state.tickets=[]
            state.error = action.payload
        },
        ticketsLoading (state,action) {
            state.loading = true
        }
    }
})

export const { ticketsSuccess,ticketsError,ticketsLoading } = ticketsSlice.actions
export default ticketsSlice.reducer;