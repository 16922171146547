import React,{useState} from 'react'
import querys from '../services/querys.js';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { DateTime} from 'luxon';
import { useNavigate } from 'react-router-dom';

const NewUsuario = () => {
  const [form,setForm] = useState({});
  const [checkbox,setCheckbox] = useState([]);
  const login = useSelector(state => state.login.login)
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCheck = ({target}) => {
    const {value} = target;
    const validation = checkbox.some( item => item === value )
    if(validation) {
      const newArray = checkbox.filter(item => item !== value)
      setCheckbox(newArray);
    }else {
      setCheckbox([
        ...checkbox,
        value
      ])
    }
    console.log(checkbox);
  }

  const onChangeInput = ({ target }) => {
    const {name,value} = target;
    setForm({
      ...form,
      [name]:value
    })
    console.log(form)
  }

  const handleInsertUser = (e) => {
    e.preventDefault();
    const body = {
        'user':form.user,
        'nombre': form.name,
        'apellido': form.lastname, 
        'email': form.email,  
        'active': 'true', 
        "password":form.password,
        "permisos": checkbox,
    }

    querys.newUser(body,dispatch,login.token).then(response => {
       navigate(`/dashboard`)
    }).catch(error => {
        console.log('error',error)
    })
}

  return (
    <form onSubmit={handleInsertUser} >
    <div className="Modal-formUsuario">
        <div className="Modal-formColumnUsuario">
            <label htmlFor="" >User:</label>
            <input type="text" pplaceholder='' onChange={onChangeInput}  name='user'/>
            <label htmlFor="" >Nombre:</label>
            <input type="text" pplaceholder='' onChange={onChangeInput}  name='name'/>
            <label htmlFor="" >Apellido:</label>
            <input type="text" placeholder='' onChange={onChangeInput}  name='lastname'/>
            <label htmlFor="" >EMAIL:</label>
            <input type="text" placeholder='' onChange={onChangeInput}  name='email'/>
            <label htmlFor="" >PASSWORD:</label>
            <input type="password" placeholder='' onChange={onChangeInput}  name='password'/>
        </div>
        <div className='Modal-formColumnUsuario'>
          <div className="Modal-containerCheck">
            <label htmlFor="clientes">Clientes</label>
            <input type="checkbox" id="clientes" name="clientes" onChange={handleCheck} value={1} className='Modal-checkbox'/>
          </div>
          <div className="Modal-containerCheck">  
            <label htmlFor="cobranza">Cobranza</label>
            <input type="checkbox" id="cobranza" name="cobranza" onChange={handleCheck} value={2} className='Modal-checkbox'/>
          </div>
          <div className="Modal-containerCheck">
            <label htmlFor="soporte">Soporte</label>
            <input type="checkbox" id="soporte" name="soporte" onChange={handleCheck} value={3} className='Modal-checkbox'/>
          </div>
          <div className="Modal-containerCheck"> 
            <label htmlFor="almacen">Almacen</label>
            <input type="checkbox" id="almacen" name="almacen" onChange={handleCheck} value={4} className='Modal-checkbox'/> 
          </div>
          <div className="Modal-containerCheck">
            <label htmlFor="usuarios">usuarios</label>
            <input type="checkbox" id="usuarios" name="usuarios" onChange={handleCheck} value={5} className='Modal-checkbox'/>  
          </div>
        </div>
    </div>
    <div className='Modal-containerBtn'>
            <input type="submit" value="Guardar" className='Modal-btnSave'/>
          </div>
  </form>
  )
}

export default NewUsuario