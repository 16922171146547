function userModel (data) {
    this.id = data?.id;
    this.name = data?.nombre;
    this.lastname = data?.apellido;
    this.password = data?.password;
    this.email = data?.email;
    this.token = data?.token;
    this.active = data?.active;
    this.permisos = data?.permisos;
    this.user = data?.user;
}
export default userModel;