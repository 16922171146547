import { createSlice } from "@reduxjs/toolkit";

const usuariosSlice = createSlice({
    name:'usuarios',
    initialState:{
        loading:false,
        usuarios:[],
        error:false
    },
    reducers: {
        usuariosSuccess (state,action) {
            state.loading = false
            state.usuarios=action.payload
            state.error = false
        },
        usuariosError (state,action) {
            state.loading = false
            state.usuarios=[]
            state.error = action.payload
        },
        usuariosLoading (state,action) {
            state.loading = true
        }
    }
})

export const { usuariosSuccess,usuariosError,usuariosLoading } = usuariosSlice.actions
export default usuariosSlice.reducer;