import React,{useState} from 'react'
import logo from '../assets/logo.png'
import portada from '../assets/internet.png'
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import querys from '../services/querys.js';
import { useSelector } from 'react-redux';

const Login = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form,setForm] = useState({});
  const loginError = useSelector(state => state.login.error)

  const onChangeInput = ({ target }) => {
    const {name,value} = target;
    setForm({
      ...form,
      [name]:value
    })
  }
  
  const handleLogin = (e) => {

    e.preventDefault();
    const body = {
      "user": form.usuario,
      "password": form.password,  
    }
    querys.postLogin(body,dispatch).then(response => {
      navigate(`/dashboard`)
    }).catch(error => {
      console.log('error',error)
    })

  }
  
  return (
    <div className='Login'>
      <div className="Login-main">
        <div className="Login-img"><img src={portada} alt="" /></div>
        <div className="Login-container">
            <div className="Login-logo"><img src={logo} alt="" /></div>
            <h1 className="Login-h1">¡Bienvenido/a!</h1>
            <form action="" className="Login-form" onSubmit={handleLogin}>
              <label htmlFor="" className='Login-label'>Usuario</label>
              <input type="text" className='Login-input' name='usuario' onChange={onChangeInput}/>
              <label htmlFor="" className='Login-label'>Password</label>
              <input type="password" className='Login-input' onChange={onChangeInput} name='password'/>
              { loginError.errorcode === 400 && <p className='Login-warning'>Usuario ó Contraseña incorrectos!</p> }
              <p className='Login-p'>¿Has olvidado tu contraseña?</p>
              <input type="submit" value="Iniciar sesión" className='Login-btn'/>
            </form>
        </div>
      </div>
    </div>
  )
}

export default Login