import React from 'react'
import logo from '../assets/logo.png'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

const Aside = ({handleMenu,menu,handleTitle}) => {
  const user = useSelector(state => state.login.login);

  return (
    <div className={menu ? 'Aside Aside-close' : 'Aside'}>
      
      <div className="Aside-header">
        <div className="Aside-logo"><img src={logo} alt="" /></div>
        <div className="Aside-close" onClick={handleMenu}>x</div>
      </div>

      <ul className='Aside-ul'>
        <Link to="home" onClick={()=>handleTitle('Home')}><li className="Aside-li"><div><i className="fa-regular fa-house"></i></div><span className='Aside-span'>Home</span></li></Link>
        <Link to="clientes" onClick={()=>handleTitle('Clientes')}><li className="Aside-li"><div><i className="fa-regular fa-user"></i></div><span className='Aside-span'>Clientes</span></li></Link>
        <Link to="cobranza" onClick={()=>handleTitle('Cobranza')}><li className="Aside-li"><div><i className="fa-sharp fa-solid fa-money-check-dollar-pen"></i></div><span className='Aside-span'>Cobranza</span></li></Link>
        <Link to="soporte" onClick={()=>handleTitle('Soporte')}><li className="Aside-li"><div><i className="fa-solid fa-headset"></i></div><span className='Aside-span'>Soporte</span></li></Link>
        <Link to="almacen" onClick={()=>handleTitle('Almacen')}><li className="Aside-li"><div><i className="fa-solid fa-warehouse-full"></i></div><span className='Aside-span'>Almacen</span></li></Link>
        <Link to="usuarios" onClick={()=>handleTitle('Usuarios')}><li className="Aside-li"><div><i className="fa-solid fa-user"></i></div><span className='Aside-span'>Usuario</span></li></Link> 
      </ul>
      
    </div>
  )
}

export default Aside