import React, { useEffect, useState } from 'react'
import querys from '../services/querys.js';
import Modal from '../components/Modal.jsx'
import { useDispatch, useSelector } from 'react-redux';
const { DateTime } = require("luxon");

const Soporte = () => {
  const dispatch = useDispatch();
  const [modal,setModal] = useState(false)
  const [modalNew,setModalNew] = useState(false)
  const [vwContainer, setVwContainer] = useState(false)
  const [ticketDetail, setTicketDetail] = useState(null);
  const [dataSearch,setDataSearch] = useState(false)
  const user = useSelector(state => state.login.login);
  const [form,setForm] = useState({});
  const tickets = useSelector(state => state.tickets.tickets.ticketsArray);
  useEffect( () => {
    querys.getAllTickets(dispatch,1);  
  }, [])

  const handleModal = (ticket) => {
    setModal(!modal)
    querys.getSearchTickets(dispatch,ticket.id).then(response => {
      setTicketDetail(response)
    }).catch(error => {
      console.log('error',error)
    }) 
    
  }

  const handleModalNew = () => {
    setModalNew(!modalNew)
  }

  const search = () => {
    console.log('buscamos');
  }

  const clean = () => {
    console.log('limpiamos');
  }

  const placeholder = 'Buscar';

  const handleEstatus = (id) => {
    switch (id) {
      case '1':
        return 'Nuevo'
      case '2':
        return 'En proceso'
      case '3':
        return 'Finalizado'
      default:
        return 'default'
    }
  }

  const fecha = (date) => {
    return date ? DateTime.fromISO(date).toFormat('D') : 'false'
  }

  const rellenarCeros = (numero) => {
    const numeroString = String(numero);
    return numeroString.padStart(6, '0');
  }

  const searchCliente = () => {
    const body = {
      "value":form.ncliente
    };
    querys.getCustomerSearchById(body,dispatch, user.token).then((response)=> {
      if (response) {
        setDataSearch(response.arrayCustomers[0])
      }
    })
  }

  const onChangeInput = ({ target }) => {
    const {name,value} = target;
    setForm({
      ...form,
      [name]:value
    })
  }

  return (
    <div className='Clientes'>
      <div className="Clientes-controles">
       <div className="Clientes-btnAdd" onClick={()=>setModalNew(true)}><i className="fa-solid fa-plus"></i>Nuevo Ticket</div>
       <form className='Clientes-containerBuscar' onSubmit={(e) => search(e)}>
          <div className='Clientes-inputs'>
              <input required type="text" id='numeroCliente' name='numeroCliente' placeholder={placeholder} className='Clientes-input' onChange={onChangeInput}/>
          </div>
          <button type='submit' className="Clientes-btnFind"><i className="fa-solid fa-magnifying-glass"></i>Buscar</button>
          <div className="Clientes-btnReset" onClick={ clean }><i className="fa-solid fa-repeat"></i>Limpiar</div>
       </form>
      </div>
        <div className="Clientes-tablaContainer">
          <div className="Clientes-table">
            <div className="thead">
              <div className="th">Fecha alta</div>
              <div className="th">N° de ticket</div>
              <div className="th">N° de cliente</div>
              <div className="th">Responsable</div>
              <div className="th">Estatus</div>
            </div>
            <div className="tbody">
              { 
              tickets && tickets.length  ?       
              tickets.map( ticket => (
                <div className="row" key={ ticket.id }>
                  <div className="th" onClick={() => { handleModal( ticket ) }}>{ fecha(ticket.date) }</div>
                  <div className="th" onClick={() => { handleModal( ticket ) }}>{ rellenarCeros(ticket.id) }</div>
                  <div className="th" onClick={() => { handleModal( ticket ) }}>{ rellenarCeros(ticket.idcliente) }</div>
                  <div className="th" onClick={() => { handleModal( ticket ) }}>{ ticket.responsableName ?  ticket.responsableName : 'sin responsable'}</div>
                  <div className="th" onClick={() => { handleModal( ticket ) }}><div className={`th-ticketEstatus th-ticketEstatus${ticket.estatus}`}>{ handleEstatus(ticket.estatus) }</div></div>
                </div>
              ))
              : 'sin tickets'
              }
            </div>
          </div>
        </div>
        {modal && ticketDetail && <Modal modal={modal} handleModal={handleModal} title={`Ticket: ${rellenarCeros(ticketDetail?.id)}`} ticket={ticketDetail}>
          <form action="" className='modal-tickets'>
            <div>
              <label htmlFor="">Fecha</label>
              <input className='inputTicketsDetails' type="text" disabled value={fecha(ticketDetail?.date)}/>
            </div>
            <div>
              <label htmlFor="">Numero de cliente</label>
              <input className='inputTicketsDetails' type="text" disabled value={rellenarCeros(ticketDetail?.idcliente)}/>
            </div>
            <div>
              <label htmlFor="">Nombre</label>
              <input className='inputTicketsDetails' type="text" disabled value={ticketDetail?.nombre} />
            </div>
            <div>
              <label htmlFor="">Telefono</label>
              <input className='inputTicketsDetails' type="text" disabled value={ticketDetail?.telefono} />
            </div>
            <div>
              <label htmlFor="">Email</label>
              <input className='inputTicketsDetails' type="text" disabled value={ticketDetail?.email} />
            </div>
            <div>
              <label htmlFor="">Estatus</label>
              <div  type="text" className='inputTicketsDetails estatusInput' onClick={() => setVwContainer(!vwContainer)}>
                <div className={`th-ticketEstatus th-ticketEstatus${ticketDetail?.estatus}`}>{ handleEstatus(ticketDetail?.estatus) }</div>
                <div><i className="fa-thin fa-circle-arrow-down"></i></div>
                { 
                vwContainer && <div className="estatusContainer">
                  <div>En progreso</div>
                  <div>Finalizado</div>
                </div>
                }
              </div>
            </div>
            <div>
              <label htmlFor="">Responsable</label>
              <div className='inputTicketsDetails' type="text" >
                {ticketDetail.responsableName} 
              </div>
            </div>
            <div className='moreDetails'>
              <label htmlFor="">Mas detalles</label>
              <input className='inputTicketsDetails' type="text" disabled value={ticketDetail.msg} />
            </div>
            <div className="ButtonSubmit">
              <button type='submit' className='modal-tickets-containerBtn'>Guardar</button>
            </div>
            <div className="historial-tickets">
              <label htmlFor="">Historial</label>
              <div className='historial-tickets-endpoint'>
                <div><div className='historial-tickets-circle'></div><p>{fecha(ticketDetail.date)}</p><span>se dio de alta el ticket</span></div>
              </div>
              {ticketDetail.responsableName && <div className='historial-tickets-endpoint'>
                <div><div className='historial-tickets-circle'></div><p>{fecha(ticketDetail.date)}</p><span>se asigno el ticket a {ticketDetail.responsableName}</span></div>
              </div>}
            </div>
          </form>
        </Modal>}

        {
        modalNew && <Modal modal={modalNew} handleModal={handleModalNew} title='Nuevo ticket'>
        <form action="" className='modal-tickets'>
          <div>
            <label htmlFor="">Fecha</label>
            <input className='inputTicketsDetails' type="text" disabled value={DateTime.now().toFormat('D')}/>
          </div>
          <div>
            <label htmlFor="">Numero de cliente</label>
            <div className='modal-tickets-buttonSearch'>
              <input className='inputTicketsDetails' type="text" name='ncliente'  onChange={onChangeInput}/>
              <div className='modal-tickets-containerBtnSearch' onClick={() => searchCliente()}><i className="fa-regular fa-magnifying-glass"></i></div>
            </div>
          </div>
          <div>
            <label htmlFor="">Nombre</label>
            <input className='inputTicketsDetails' disabled type="text" value={dataSearch?.name}/>
          </div>
          <div>
            <label htmlFor="">Telefono</label>
            <input className='inputTicketsDetails' disabled type="text" value={dataSearch?.phone}/>
          </div>
          <div>
            <label htmlFor="">Email</label>
            <input className='inputTicketsDetails' disabled type="text"  value={dataSearch?.email}/>
          </div>
          <div>
            <label htmlFor="">Estatus</label>
            <div  type="text" className='inputTicketsDetails estatusInput' onClick={() => setVwContainer(!vwContainer)}>
              <div className={`th-ticketEstatus th-ticketEstatus1`}>Nuevo</div>
              <div><i className="fa-thin fa-circle-arrow-down"></i></div>
              { 
              vwContainer && <div className="estatusContainer">
                <div>En progreso</div>
                <div>Finalizado</div>
              </div>
              }
            </div>
          </div>
          <div>
            <label htmlFor="">Responsable</label>
            <div className='inputTicketsDetails' type="text" >
              sin responsable
            </div>
          </div>
          <div className='moreDetails'>
            <label htmlFor="">Mas detalles</label>
            <input className='inputTicketsDetails' type="text" placeholder='Mas detalles sobre el ticket' />
          </div>
          <div className="ButtonSubmit">
            <button type='submit' className='modal-tickets-containerBtn'>Guardar</button>
          </div>
        </form>
        </Modal>}
    </div>
  )
}

export default Soporte