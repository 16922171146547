import React, { useState, useEffect } from 'react'
import Modal from '../components/Modal.jsx'
import querys from '../services/querys.js';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import Search from '../components/search.jsx';
import Moment from 'moment'
import ResponsivaPdf from '../components/responsivapdf.jsx'
import { pdf, PDFViewer } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

const Almacen = () => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.login.login);
  const [modalEntrada,setModalEntrada] = useState(false)
  const [modalProducto,setModalProducto] = useState(false)
  const [modalCategoria,setModalCategoria] = useState(false)
  const [modalMarca,setModalMarca] = useState(false)
  const [modalInfo,setModalInfo] = useState(false)
  const [categorias, setCategorias] = useState([])
  const [marcas, setMarcas] = useState([])
  const [productos, setProductos] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [nPage, setNpage] = useState(1)
  const [categoriaForm,setcategoriaForm] = useState({});
  const [infoModal, setInfoModdal] = useState({})
  const [handleContainer, setHandlerContainer] = useState(false)
  const [modalConsigna,setModalConsigna] = useState(false)
  const [AllProducts, setAllProductos] = useState([])
  const [allUser, setAllUser] = useState([])
  const [formResponsiva, setFormResponsiva] = useState({productos:[]})
  const [formEntrada, setFormEntrada] = useState({})
  const [addProductos, setAddProductos] = useState([])
  const [errorBar, setErrorBar] = useState({
    active:false,
    msg:''
  })
  const [responsiva, setResponsiva] = useState({})

  useEffect(()=> {
    querys.getCategorias(dispatch,user.token).then(response => {
      setCategorias(response.categorias)
    });  
    querys.getMarcas(dispatch,user.token).then(response => {
      setMarcas(response.categorias)
    }); 
    querys.getAllProducts(dispatch,user.token,1).then(response => {
      setProductos(response.arrayProductos)
      setTotalPage(response.totalPage)
    });
    querys.getProducts(dispatch,user.token).then(response => {
      setAllProductos(response)
    }); 
    querys.getUsuarios(dispatch,user.token).then(response => {
      setAllUser(response)
    });   
  },[])

  const handleModalEntrada = () => {
    setModalEntrada(!modalEntrada)
  }
  const handleModalProducto = () => {
    setModalProducto(!modalProducto)
  }
  const handleModalCategoria = () => {
    setModalCategoria(!modalCategoria)
  }
  const handleModalMarca = () => {
    setModalMarca(!modalMarca)
  }
  const handleModalInfo = (producto) => {
    setInfoModdal(producto)
    setModalInfo(!modalInfo)
  }
  const handleModalConsigna = () => {
    setModalConsigna(!modalConsigna)
  }

  const addCategoria = (e) => {
    e.preventDefault()
    const body = {
      nombre: categoriaForm.categoria
    }
    querys.addCategorias(body,dispatch,user.token).then(response => {
      document.getElementById('formCategoria').reset()
      setCategorias(response.categorias)
    })
  }
  const formateador = new Intl.NumberFormat("en", { style: "currency", "currency": "MXN" });

  const onChangeInput = ({ target }) => {
    const {name,value} = target;
    setcategoriaForm({
      ...categoriaForm,
      [name]:value
    })
  }

  const handlePage = () => {
    querys.getAllProducts(dispatch,user.token,nPage+1).then(response => {
      setProductos(response.arrayProductos)
      setTotalPage(response.totalPage)
    }); 
    setNpage(nPage+1)
  }
  const handlePageMenor = () => {
    querys.getAllProducts(dispatch,user.token,nPage-1).then(response => {
      setProductos(response.arrayProductos)
      setTotalPage(response.totalPage)
    }); 
    setNpage(nPage-1)
  }

  const handlerOpenModal = (funcion) => {
    funcion(true)
    setHandlerContainer(false)
  }

  const handleFormResponsable = (name, item) => {
    setFormResponsiva({...formResponsiva, [name]:item})
  }

  const handleAddProductoResponsiva = (name, item) => {
    
    if (item.stock > 0) {
      let array = addProductos;
      if (array.filter(producto => producto.id === item.id).length) {
        setErrorBar({
          active:true,
          msg: 'Producto Agregado'
        })
        setTimeout(() => {
          setErrorBar({
            active:false,
            msg: ''
          })
        }, "2000");
      } else {
        let array = [...addProductos];
        array.push(item)
        setAddProductos(array)
        setFormResponsiva({ ...formResponsiva, 'productos': array })
      }
    } else {
      setErrorBar({
        active:true,
        msg: 'Stock Insuficiente - se encuentra en 0'
      })
      setTimeout(() => {
        setErrorBar({
          active:false,
          msg: ''
        })
      }, "2000");
    }
  }

  const handleCantidadProducto = (e,stock, item) =>  {
    if (stock < e.target.value || e.target.value === '0') {
      e.target.value = ""
      setErrorBar({
        active:true,
        msg: 'Stock Insuficiente'
      })
      setTimeout(() => {
        setErrorBar({
          active:false,
          msg: ''
        })
      }, "2000");
    } else if(e.target.value != '' && e.target.value != item.cantidad) {
      let array = formResponsiva.productos;
      let newArray = array.filter(producto => producto.id != item.id)
      let element = array.find(producto => producto.id === item.id)
      newArray.push({ ...element,cantidad: e.target.value })
      setFormResponsiva({ ...formResponsiva, 'productos': newArray })
    }
  }

  const handleDeleteProducto = (item) => {
    let array = addProductos;
    let newArray = array.filter(producto => producto.id != item.id)
    setAddProductos(newArray)
    setFormResponsiva({ ...formResponsiva, 'productos': newArray })
  }

  const handleSubmitResponsiva = (e) => {
    e.preventDefault()
    const body = {...formResponsiva, fecha: Moment().format('YYYY-MM-DD'), user: user.id}
    querys.addResponsiva(body,dispatch,user.token).then(response => {
      if (response.productos) {
        handleModalConsigna()
        setAddProductos([])
        setFormResponsiva({})
        setResponsiva(response)
        generatePdfDocument(response)
      }
    })
  }

  const generatePdfDocument = async (documentData,fileName) => {
    const blob = await pdf((
        <ResponsivaPdf
            title='My PDF'
            pdfDocumentData={documentData}
        />
    )).toBlob();
    saveAs(blob, fileName);
};

  const handleAddProductoEntrada = (name, item) => {
    setFormEntrada({...formEntrada, producto: item})
  }
  
  const handleRemoveProductoEntrada = () => {
    setFormEntrada({...formEntrada, producto: {}})
  }

  return (
    <div className='Clientes'>
      <div className="Clientes-controles controles-almacen">
        <div className="Clientes-btnAdd" onClick={() => setHandlerContainer(true)}>
          <i className="fa-solid fa-plus"></i> Agregar
        </div>
        { handleContainer && <div className='containerControl-Agregar'>
          <div onClick={ () => handlerOpenModal(setModalEntrada) }>Entrada</div>
          <div onClick={ () => handlerOpenModal(setModalProducto) }>Producto</div>
          <div onClick={ () => handlerOpenModal(handleModalCategoria) }>Categoria</div>
          <div onClick={ () => handlerOpenModal(setModalMarca) }>Marca</div>
        </div> }
        { handleContainer && <div onClick={() => setHandlerContainer(false)} className='background-container-button'></div> }
        <div className="Clientes-btnAdd generarConsigna" onClick={() => handleModalConsigna(true)}>
          <i className="fa-solid fa-plus"></i> Generar consigna
        </div>
        <form className='Clientes-containerBuscar' >
          <div className='Clientes-inputs'>
              <input required type="text" id='numeroCliente' name='numeroCliente' className='Clientes-input' />
          </div>
          <button type='submit' className="Clientes-btnFind"><i className="fa-solid fa-magnifying-glass"></i>Buscar</button>
          <div className="Clientes-btnReset" ><i className="fa-solid fa-repeat"></i>Limpiar</div>
        </form>
      </div>
      <div className="Clientes-tablaContainer">
        <div className="Clientes-table">
          <div className="thead">
            <div className="th">Modelo</div>
            <div className="th">Marca</div>
            <div className="th">categoria</div>
            <div className="th">Precio</div>
            <div className="th">Stock</div>
          </div>
          <div className="tbody">
          { 
            productos && productos.length && productos.map(producto => (
              <div className="row" key={ producto.modelo } onClick={ () => handleModalInfo(producto) }>
                <div className="th">{ producto.modelo }</div>
                <div className="th">{ producto.marca }</div>
                <div className="th">{ producto.categoria }</div>
                <div className="th">{ formateador.format(producto.precioVenta) }</div>
                <div className="th" >{ producto.stock }</div>
              </div>
            ))
            }
          </div>
          <div className='controlPaginas'> 
            { nPage > 1 && <div onClick={()=>handlePageMenor()}><i className="fa-light fa-circle-arrow-left"></i></div>}
            <label htmlFor="">pag -  {nPage}</label>
            { nPage < totalPage &&  <div onClick={()=>handlePage()}><i className="fa-light fa-circle-arrow-right"></i></div> }
          </div>
        </div>
      </div>
      {
        <Modal modal={modalEntrada} title='Entradas' handleModal={handleModalEntrada}>
          <form action="" className='modal-tickets'>
            <Search 
              isRequired={false} 
              array={ AllProducts.arrayProductos } 
              title='Asignar producto' 
              itemViews={['modelo','marca', 'categoria', 'descripcion', 'numserie']} 
              funcion={ handleAddProductoEntrada } 
              funcionRemove={ handleRemoveProductoEntrada }
              name='Buscar producto'
              placeholderSearch='Busca por codigo, numero de serie, marca, categoria'
            />
            <div>
              <label htmlFor="">Modelo de producto</label>
              <input className='inputTicketsDetails' type="text" disabled 
                value={ formEntrada.producto && formEntrada.producto.modelo ?  formEntrada.producto.modelo : ''}
              />
            </div>
            <div>
              <label htmlFor="">Descripción</label>
              <input className='inputTicketsDetails' type="text" disabled 
                value={ formEntrada.producto && formEntrada.producto.descripcion ?  formEntrada.producto.descripcion : ''}
              />
            </div>
            <div>
              <label htmlFor="">Categoria</label>
              <input className='inputTicketsDetails' type="text" disabled 
                value={ formEntrada.producto && formEntrada.producto.categoria ?  formEntrada.producto.categoria : ''}
              />
            </div>
            <div>
              <label htmlFor="">Precio</label>
              <input className='inputTicketsDetails' type="text" disabled 
                value={ formEntrada.producto && formEntrada.producto.precioVenta ?  formEntrada.producto.precioVenta : ''}
              />
            </div>
            <div>
              <label htmlFor="">Stock Actual</label>
              <input className='inputTicketsDetails' type="text" disabled 
                value={ formEntrada.producto && formEntrada.producto.stock ?  formEntrada.producto.stock : 0}
              />
            </div>
            <div>
              <label htmlFor="">Piezas añadir</label>
              <input className='inputTicketsDetails' type="number" required/>
            </div>
            <div className="ButtonSubmit">
              <button type='submit' className='modal-tickets-containerBtn'>Añadir Entrada</button>
            </div>
          </form>
        </Modal>
      }
      {
        <Modal modal={modalProducto} title='Agregar Producto' handleModal={handleModalProducto}>
          <form action="" className='modal-products'>
            <div>
              <label htmlFor="">Numero de serie</label>
              <input className='inputTicketsDetails' type="text"/>
            </div>
            <div>
              <label htmlFor="">Modelo</label>
              <input className='inputTicketsDetails' type="text"/>
            </div>
            <div>
              <label htmlFor="">Descripción</label>
              <textarea name="" id="" cols="30" rows="6"></textarea>
            </div>
            <Search 
              isRequired={true} 
              array={ marcas } 
              title='Marca' 
              itemViews={['nombre']} 
              funcion={handleAddProductoResponsiva} 
              name='producto'
            />
            <div></div>
            <Search 
              isRequired={true} 
              array={ categorias } 
              title='Marca' 
              itemViews={['nombre']} 
              funcion={handleAddProductoResponsiva} 
              name='producto'
            />
            <div>
              <label htmlFor="">Codigo SAT</label>
              <input className='inputTicketsDetails' type="text"/>
            </div>
            <div>
              <label htmlFor="">Precio de compra</label>
              <input className='inputTicketsDetails' type="number"/>
            </div>
            <div>
              <label htmlFor="">Precio de venta</label>
              <input className='inputTicketsDetails' type="number"/>
            </div>
            <div>
              <label htmlFor="">Minimo en stock</label>
              <input className='inputTicketsDetails' type="number"/>
            </div>
            <div className="ButtonSubmit">
              <button type='submit' className='modal-tickets-containerBtn'>Agregar Producto</button>
            </div>
          </form>
        </Modal>
      }

      {
        <Modal modal={modalConsigna} title='Generar responsiva' handleModal={handleModalConsigna}>
          <form action="" className='modal-Almacen responsivaContainer' onSubmit={handleSubmitResponsiva}>
            <Search isRequired={true} array={ allUser.arrayUsuarios } title='Asignar Responsable' itemViews={['name','lastname']} funcion={handleFormResponsable} name='responsable'/>
            <Search isRequired={false} addType={ true } array={ AllProducts.arrayProductos } title='Asignar producto' itemViews={['modelo','marca', 'categoria', 'descripcion', 'numserie']} funcion={handleAddProductoResponsiva} name='producto'/>
            <div className='almacen-responsable-asignados'>
              <label htmlFor="">Productos Asignados</label>
              <div className='containerCategoria'>
                { errorBar.active && <div className='containerCategoriaThBarError'>{ errorBar.msg }</div> }
                <div className='containerCategoriaRow headCategoria'>
                  <div className='containerCategoriaTh'>Producto</div>
                  <div className='containerCategoriaTh'>Stock</div>
                  <div className='containerCategoriaTh'>Cantidad</div>
                  <div className='containerCategoriaTh'></div>
                </div>
                { addProductos && addProductos.length > 0 && addProductos.map(producto => {
                  return (
                    <div className='containerCategoriaRow'>
                      <div className='containerCategoriaTh'>{ producto.modelo } | { producto.marca }</div>
                      <div className='containerCategoriaTh'>{ producto.stock }</div>
                      <div className='containerCategoriaTh'> 
                        <input
                          id={ `inputCantidad`+producto.id }
                          type="number" 
                          disabled={ producto.stock > 0 ? false : true } 
                          max={ producto.stock }
                          onBlur={ (e) => handleCantidadProducto(e, producto.stock, producto) }
                          required={true}
                          />
                      </div>
                      <div className='containerCategoriaTh'> 
                        <i onClick={() => handleDeleteProducto(producto)} className="fa-solid fa-trash"></i> 
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>

            <div className="ButtonSubmitAlmacen">
              <button type='submit' className='modal-tickets-containerBtnCancelar'>Cancelar</button>
              <button type='submit' className='modal-tickets-containerBtn'>Generar</button>
            </div>
          </form>
        </Modal>
      }

      {
        <Modal modal={modalCategoria} title='Agregar Categoria' handleModal={handleModalCategoria}>
          <form onSubmit={ (e) => addCategoria(e) } className='modal-Almacen' id="formCategoria">
            <div>
              <label htmlFor="">Titulo de Categoria</label>
              <input className='inputTicketsDetails' onChange={onChangeInput} name='categoria' type="text"/>
            </div>
            <div className="ButtonSubmit">
              <button type='submit' className='modal-tickets-containerBtn'>Agregar Categoria</button>
            </div>
            <div>
              <label htmlFor="">Categorias existentes</label>
              <br />
              <div className='containerCategoria'>
                {
                  categorias.map(categoria => {
                    return(
                      <label>{categoria.nombre}</label>
                    )
                  })
                }
              </div>
            </div>
          </form>
        </Modal>
      }

      {
        <Modal modal={modalMarca} title='Agregar Marca' handleModal={handleModalMarca}>
          <form action="" className='modal-Almacen'>
            <div>
              <label htmlFor="">Titulo de Marca</label>
              <input className='inputTicketsDetails' type="text"/>
            </div>
            <div className="ButtonSubmit">
              <button type='submit' className='modal-tickets-containerBtn'>Agregar Marca</button>
            </div>
            <div>
              <label htmlFor="">Marcas existentes</label>
              <br />
              <div className='containerCategoria'>
                {
                  marcas.map(categoria => {
                    return(
                      <label>{categoria.nombre}</label>
                    )
                  })
                }
              </div>
            </div>
          </form>
        </Modal>
      }
      {
        <Modal modal={modalInfo} title='Producto' handleModal={handleModalInfo}>
          <form action="" className='modal-products'>
            <div>
              <label htmlFor="">Numero de serie</label>
              <input className='inputTicketsDetails' value={infoModal.numserie} type="text" disabled/>
            </div>
            <div>
              <label htmlFor="">Modelo</label>
              <input className='inputTicketsDetails' value={infoModal.modelo} type="text" disabled/>
            </div>
            <div>
              <label htmlFor="">Descripción</label>
              <textarea name="" id="" cols="30" rows="6" value={infoModal.descripcion} disabled></textarea>
            </div>
            <div>
              <label htmlFor="">Marca</label>
              <input className='inputTicketsDetails' value={infoModal.marca} type="text" disabled/>
            </div>
            <div></div>
            <div>
              <label htmlFor="">Categoria</label>
              <input className='inputTicketsDetails' value={infoModal.categoria} type="text" disabled/>
            </div>
            <div>
              <label htmlFor="">Codigo SAT</label>
              <input className='inputTicketsDetails' value={infoModal.codigosat} type="text" disabled/>
            </div>
            <div>
              <label htmlFor="">Precio de compra</label>
              <input className='inputTicketsDetails' value={formateador.format(infoModal.precioCompra)} type="text" disabled/>
            </div>
            <div>
              <label htmlFor="">Precio de venta</label>
              <input className='inputTicketsDetails' value={formateador.format(infoModal.precioVenta)} type="text" disabled/>
            </div>
            <div>
              <label htmlFor="">Stock</label>
              <input className='inputTicketsDetails' value={infoModal.stock} type="text" disabled/>
            </div>
            <div>
              <label htmlFor="">Minimo en stock</label>
              <input className='inputTicketsDetails' type="number"/>
            </div>
            <div>
              <label htmlFor="">Piezas de entrada</label>
              <input className='inputTicketsDetails' value={infoModal.entradas} type="text" disabled/>
            </div>
            <div>
              <label htmlFor="">Piezas de salida</label>
              <input className='inputTicketsDetails' value={infoModal.salidas} type="text" disabled/>
            </div>
          </form>
        </Modal>
      }
      
      
    </div>
  )
}

export default Almacen