function customersModel (data) {
    this.id = data?.id;
    this.name = data?.name;
    this.lastname = data?.lastname;
    this.user = data?.user;
    this.password = data?.password;
    this.email = data?.email;
    this.phone = data?.phone;
    this.estado = data?.estado;
    this.ciudad = data?.ciudad;
    this.calle = data?.calle;
    this.pais = data?.pais;
    this.noexterior = data?.noexterior;
    this.nointerior = data?.nointerior;
    this.colonia = data?.colonia;
    this.codigopostal = data?.codigopostal;
    this.alta = data?.alta;
    this.plan = data?.plan;
    this.curp =data?.curp;
    this.localidad = data?.localidad;
    this.razonsocial = data?.razonsocial;
    this.regimenfiscal = data?.regimenfiscal;
    this.rfc = data?.rfc;
    this.cfdi = data?.cfdi;
    this.active = data?.active;
}

export default customersModel;
