import { createSlice } from "@reduxjs/toolkit";

const loginSlice = createSlice( { 
    name:'login',
    initialState:{
        loading:false,
        login:[],
        error:false
    },
    reducers: {
        loginSuccess (state,action) {
            state.loading = false
            state.login=action.payload
            state.error = false
        },
        loginError (state,action) {
            state.loading = false
            state.login=[]
            state.error = action.payload
        },
        loginLoading (state,action) {
            state.loading = true
        }
    }
})

export const { loginSuccess,loginError,loginLoading } = loginSlice.actions
export default loginSlice.reducer;