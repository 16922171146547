import { createSlice } from "@reduxjs/toolkit";

const usuarioSlice = createSlice({
    name:'usuario',
    initialState:{
        loading:false,
        usuario:[],
        error:false
    },
    reducers: {
        usuarioSuccess (state,action) {
            state.loading = false
            state.usuario=action.payload
            state.error = false
        },
        usuarioError (state,action) {
            state.loading = false
            state.usuario=[]
            state.error = action.payload
        },
        usuarioLoading (state,action) {
            state.loading = true
        }
    }
})

export const { usuarioSuccess,usuarioError,usuarioLoading } = usuarioSlice.actions
export default usuarioSlice.reducer;