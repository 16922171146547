import {router} from "./router";
import { RouterProvider } from "react-router-dom";
function App() {
  return (
    <div className="App">
     <RouterProvider router={router}/>
    </div>
  );
}

export default App;
