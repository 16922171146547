import React from 'react';

const Modal = ({modal,handleModal,title, ticket, children}) => {

  const rellenarCeros = (numero) => {
    const numeroString = String(numero);
    return numeroString.padStart(6, '0');
  }

  return (
    <div className={modal ? 'Modal' : 'Modal-hidden'}>
        <div className="Modal-container">
            <div className="Modal-title">
                <h3>{ title }</h3>
                <div className="Modal-close" onClick={ handleModal }>X</div>
            </div>
            <div className="Modal-form">
              {
                children
              }
            </div>
        </div>
    </div>
  )
};

export default Modal;