import { createSlice } from "@reduxjs/toolkit";

const categoriasSlice = createSlice({
    name:'categorias',
    initialState:{
        loading:false,
        categorias:[],
        error:false
    },
    reducers: {
        categoriasSuccess (state,action) {
            state.loading = false
            state.categorias=action.payload
            state.error = false
        },
        categoriasError (state,action) {
            state.loading = false
            state.categorias=[]
            state.error = action.payload
        },
        categoriasLoading (state,action) {
            state.loading = true
        }
    }
})

export const { categoriasSuccess,categoriasError,categoriasLoading } = categoriasSlice.actions
export default categoriasSlice.reducer;