import { createSlice } from "@reduxjs/toolkit";

const customerSlice = createSlice({
    name:'customer',
    initialState:{
        loading:false,
        customer:[],
        error:false
    },
    reducers: {
        customerSuccess (state,action) {
            state.loading = false
            state.customer=action.payload
            state.error = false
        },
        customerError (state,action) {
            state.loading = false
            state.customer=[]
            state.error = action.payload
        },
        customerLoading (state,action) {
            state.loading = true
        }
    }
})

export const { customerSuccess,customerError,customerLoading } = customerSlice.actions
export default customerSlice.reducer;