import React,{useState,useEffect} from 'react'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import querys from '../services/querys';
import { useNavigate } from 'react-router-dom';


const EditCustomer = ( { customer } ) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user = useSelector(state => state.login.login);
    const [form,setForm] = useState({});
    

    useEffect(() => {
        console.log(customer)
    }, [customer]);

    const onChangeInput = ({ target }) => {
        const {name,value} = target;
        setForm({
          ...form,
          [name]:value
        })
      }

      

    const handleUpdateCustomer = (e) => {

        e.preventDefault();
        const body = {
            'id':customer.numContrato,
            'name': form.name,
            'lastname': form.lastname, 
            'rfc': form.rfc,  
            'curp': form.curp, 
            'email': form.email, 
            'phone': form.phone, 
            'razonsocial': form.razonsocial, 
            'regimenfiscal': form.regimenfiscal, 
            'cfdi': form.cfdi, 
            'noexterior': form.noexterior, 
            'nointerior': form.nointerior, 
            'codigopostal': form.codigopostal, 
            'colonia': form.colonia, 
            'ciudad': form.ciudad, 
            'localidad': form.localidad, 
            'estado': form.estado, 
            'plan': form.plan, 
            'calle': form.calle,
            'pais': form.pais,  
            'active': form.active, 
            "password":"12345",  
        }
        querys.updateCustomer(body,dispatch,user.token).then(response => {
            navigate(`/dashboard`)
        })
    }

  return (
    <form action="" onSubmit={handleUpdateCustomer}>
        {
           
            <div className="Modal-formContainer" key={ customer.id }>
                <div className="Modal-formColumn">
                    <label htmlFor="" >No. Cliente:</label>
                    <input type="text" placeholder={customer.id} value={customer.id} disabled/>
                    <label htmlFor="" >Nombre:</label>
                    <input type="text" placeholder={customer.name}  onChange={onChangeInput}  name='name'/>
                    <label htmlFor="" >Apellido:</label>
                    <input type="text" placeholder={customer.lastname}  onChange={onChangeInput}  name='lastname'/>
                    <label htmlFor="" >R.F.C:</label>
                    <input type="text" placeholder={customer.rfc}  onChange={onChangeInput}  name='rfc'/>
                    <label htmlFor="" >C.U.R.P.:</label>
                    <input type="text" placeholder={customer.curp}  onChange={onChangeInput}  name='curp'/>
                    <label htmlFor="" >EMAIL:</label>
                    <input type="text" placeholder={customer.email}  onChange={onChangeInput}  name='email'/>
                    <label htmlFor="" >CELULAR:</label>
                    <input type="text" placeholder={customer.phone}  onChange={onChangeInput}  name='phone'/>
                    <label htmlFor="" >Razón social:</label>
                    <input type="text" placeholder={customer.razonsocial}  onChange={onChangeInput}  name='razonsocial'/>
                    <label htmlFor="" >Régimen Fiscal</label>
                    <input type="text" placeholder={customer.regimenfiscal}  onChange={onChangeInput}  name='regimenfiscal'/>
                    <label htmlFor="" >Uso CFDI:</label>
                    <input type="text" placeholder={customer.cfdi}  onChange={onChangeInput}  name='cfdi'/> 
                    <label htmlFor="" >No. Ext.:</label>
                    <input type="text" placeholder={customer.noexterior}  onChange={onChangeInput}  name='noexterior'/>
                </div>
                <div className="Modal-formColumn">
                    <label htmlFor="" >No.Int.:</label>
                    <input type="text" placeholder={customer.nointerior}  onChange={onChangeInput}  name='nointerior'/>
                    <label htmlFor="" >Código Postal:</label>
                    <input type="text" placeholder={customer.codigopostal}  onChange={onChangeInput}  name='codigopostal'/>
                    <label htmlFor="" >Colonia:</label>
                    <input type="text" placeholder={customer.colonia}  onChange={onChangeInput}  name='colonia'/>
                    <label htmlFor="" >Calle:</label>
                    <input type="text" placeholder={customer.calle}  onChange={onChangeInput}  name='calle'/>
                    <label htmlFor="" >Ciudad:</label>
                    <input type="text" placeholder={customer.ciudad}  onChange={onChangeInput}  name='ciudad'/>
                    <label htmlFor="" >Localidad:</label>
                    <input type="text" placeholder={customer.localidad}  onChange={onChangeInput}  name='localidad'/>
                    <label htmlFor="" >Estado:</label>
                    <input type="text" placeholder={customer.estado}  onChange={onChangeInput}  name='estado'/>
                    <label htmlFor="" >Pais:</label>
                    <input type="text" placeholder={customer.pais}  onChange={onChangeInput}  name='pais'/>
                    <label htmlFor="" >Paquete:</label>
                    <input type="text" placeholder={customer.plan}  onChange={onChangeInput}  name='plan'/>
                    <label htmlFor="" >Estatus del servicio:</label>
                    <input type="text" placeholder={customer.active === 'true' ? 'Activo' : 'Vencido'}  onChange={onChangeInput}  name='active'/>
                </div>
            </div>


    
        }
        <div className='Modal-containerBtn'>
                <input type="submit" value="Guardar" className='Modal-btnSave'/>
        </div>
    </form>
  )
}

export default EditCustomer