import React from 'react'
import { loginSuccess } from '../redux/reducers/loginSlice'
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Header = ({handleMenu,title}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const login = useSelector(state => state.login.login)

  const logout = () => {
    dispatch(loginSuccess(false))
    navigate(`/`)
  }

  return (
    <div className='Header'>
      <i className="fa-solid fa-bars Header-bars" onClick={handleMenu} ></i>
      <h2 className='Header-h2'>{title}</h2>
      <div className="Header-profile">
        <i className="fa-regular fa-bell Header-bell" ></i>
        <div className="Header-notification">
          <div className="Header-nameProfile"><p className='Header-p'>Noe Aviles</p></div>
        </div>
        <div onClick={logout}><i className="fa-regular fa-right-from-bracket"></i></div>
      </div>
      
    </div>
  )
}

export default Header