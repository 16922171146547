import React,{useState,useEffect} from 'react'
import ModalClientes from '../components/ModalClientes'
import { useDispatch } from 'react-redux';
import querys from '../services/querys.js';
import { useSelector } from 'react-redux';

  const Clientes = () => {

  const [modal,setModal] = useState(false)
  const [numContrato,setNumContrato] = useState();
  const dispatch = useDispatch();
  const user = useSelector(state => state.login.login);
  const customers = useSelector( state => state.customers.customers.arrayCustomers ); //indefinido
  const paginado = useSelector( state => state.customers.customers.totalPage );
  const dataSearch = useSelector( state => state.customers.customers );
  const [array,setarray] = useState(false);
  const [form,setForm] = useState({});
  const [placeholder,setPlaceholder] = useState("Buscar...")

  useEffect( () => {
    querys.getAllCustomers(dispatch,user.token,1);  
  }, [])

  const handleModal = (customer) => {
    setModal(!modal)
    setNumContrato(customer)
  }

  const handleActiveCustomer = (id,active) => {
    
    if (active === 'true' ) {
        active = 'false'
    }else { 
      active = 'true'
    }
    const body = {
      'id':id,
      'active':active
    }
    querys.updateCustomer(body,dispatch,user.token)
    querys.getAllCustomers(dispatch,user.token,1)
      setarray(false)
    
  }

  const search = (e) => {
    e.preventDefault();
    const body = {
      "value":form.numeroCliente
    };
    querys.getCustomerSearchById(body,dispatch, user.token).then((response)=> {
      setarray(response.arrayCustomers)
      if (response.length > 0 ) {
        setarray(response)
      }
    })
  }

  const clean = () => {
    setarray(false);
    document.getElementById("numeroCliente").value="";
  }

  const onChangeInput = ({ target }) => {
    const {name,value} = target;
    setForm({
      ...form,
      [name]:value
    })
  }

  const rellenarCeros = (numero) => {
    const numeroString = String(numero);
    return numeroString.padStart(6, '0');
  }

  return (
    <div className='Clientes'>
      <div className="Clientes-controles">
       <div className="Clientes-btnAdd" onClick={()=>handleModal()}><i className="fa-solid fa-plus"></i>Nuevo Registro</div>
       <form className='Clientes-containerBuscar' onSubmit={(e) => search(e)}>
          <div className='Clientes-inputs'>
              <input required type="text" id='numeroCliente' name='numeroCliente' placeholder={placeholder} className='Clientes-input' onChange={onChangeInput}/>
          </div>
          <button type='submit' className="Clientes-btnFind"><i className="fa-solid fa-magnifying-glass"></i>Buscar</button>
          <div className="Clientes-btnReset" onClick={clean }><i className="fa-solid fa-repeat"></i>Limpiar</div>
       </form>
      </div>
      <div className="Clientes-tablaContainer">
      <div className="Clientes-table">
          <div className="thead">
            <div className="th">Num Cliente</div>
            <div className="th">Nombres</div>
            <div className="th">Apellidos</div>
            <div className="th">correo</div>
            <div className="th">Telefono</div>
            <div className="th">Estatus</div>
          </div>
          <div className="tbody">
            {
              array === false ? 
              
              customers && customers.length && customers.map( customer => (
                <div className="row" key={ customer.id }>
                  <div className="th" onClick={() => { handleModal( customer ) }}>{ rellenarCeros(customer.id) }</div>
                  <div className="th" onClick={() => { handleModal( customer ) }}>{ customer.name }</div>
                  <div className="th" onClick={() => { handleModal( customer ) }}>{ customer.lastname }</div>
                  <div className="th" onClick={() => { handleModal( customer ) }}>{ customer.email }</div>
                  <div className="th" onClick={() => { handleModal( customer ) }}>{ customer.phone }</div>
                  <div className="th"><div className={customer.active === 'true' ? 'Clientes-btn btnIsActive' : 'Clientes-btn' } onClick={() => handleActiveCustomer(customer.id,customer.active)}><div className='Clientes-circulo'></div></div></div>
                </div>
              ))
                :
              array && array.length && array.map( customer => (
                <div className="row" key={ customer.id }>
                  <div className="th" onClick={() => { handleModal( customer ) }}>{ rellenarCeros(customer.id) }</div>
                  <div className="th" onClick={() => { handleModal( customer ) }}>{ customer.name }</div>
                  <div className="th" onClick={() => { handleModal( customer ) }}>{ customer.lastname }</div>
                  <div className="th" onClick={() => { handleModal( customer ) }}>{ customer.email }</div>
                  <div className="th" onClick={() => { handleModal( customer ) }}>{ customer.phone }</div>
                  <div className="th"><div className={customer.active === 'true' ? 'Clientes-btn btnIsActive' : 'Clientes-btn' } onClick={() => handleActiveCustomer(customer.id,customer.active)}><div className='Clientes-circulo'></div></div><span className = { customer.active === 'true' ? 'Clientes-estatusTrue' : 'Clientes-estatusFalse'}>{ customer.active }</span></div>
                </div>
              ) )
              
            }
          </div>
        </div>
      </div>
      <ModalClientes modal={modal} handleModal={handleModal} customer={numContrato}/>
    </div>
  )
}

export default Clientes