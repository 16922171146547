import { configureStore } from '@reduxjs/toolkit';
import loginSlice from './reducers/loginSlice'
import customersSlice from './reducers/customersSlice'
import customerSlice from './reducers/customerSlice';
import notificationSlice from './reducers/notificationSlice';
import ticketsSlice from './reducers/ticketsSlice';
import usuariosSlice from './reducers/usuariosSlice';
import categoriasSlice  from './reducers/categoriasSlice';
import usuarioSlice from './reducers/usuarioSlice';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
  }

const persistedUser =  persistReducer(persistConfig, loginSlice);

export const store = configureStore( {
    reducer: {
        login:persistedUser,
        customers:customersSlice,
        notification:notificationSlice,
        customerSlice:customerSlice,
        usuarios:usuariosSlice,
        usuario:usuarioSlice,
        tickets: ticketsSlice,
        categorias: categoriasSlice
    }, middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
} )

export const persistor = persistStore(store);