import React,{useState} from 'react'
function ModalCobranza({modal,handleModal}) {
  const [form,setForm] = useState({});

  const search = () => {
      console.log("Buscar cliente")
  }
  const onChangeInput = ({ target }) => {
    const {name,value} = target;
    setForm({
      ...form,
      [name]:value
    })
  }

  return (
    <div className={modal ? 'Modal' : 'Modal-hidden'}>
      <div className="Modal-container">
          <div className="Modal-title">
              <h3>Registro de Pago</h3>
              <div className="Modal-close" onClick={handleModal}>X</div>
          </div>
        <form className='Clientes-containerBuscar' onSubmit={(e) => search(e)}>
          <div className='Clientes-inputs'>
              <input required type="text" id='numeroCliente' name='numeroCliente' placeholder='Numero cliente' className='Clientes-input' onChange={onChangeInput}/>
          </div>
          <button type='submit' className="Clientes-btnFind"><i className="fa-solid fa-magnifying-glass"></i>Buscar</button>
        </form>
        <div className="Modal-cobranzaTable">
          <div className="Clientes-table">
            <div className="thead">
              <div className="th">No contrato</div>
              <div className="th">Nombre</div>
              <div className="th">Periodo</div>
              <div className="th">Monto</div>
              <div className="th"></div>
            </div>
            <div className="tbody">
                  <div className="row">
                    <div className="th">0010</div>
                    <div className="th">Juan Guzman</div>
                    <div className="th">Julio</div>
                    <div className="th">$500.00</div>
                    <div className="th"><input type="checkbox" className='checkBox'/></div>
                  </div>
                  <div className="row">
                    <div className="th">0010</div>
                    <div className="th">Juan Guzman</div>
                    <div className="th">Agosto</div>
                    <div className="th">$500.00</div>
                    <div className="th"><input type="checkbox" className='checkBox'/></div>
                  </div>
                  <div className="row">
                    <div className="th">0010</div>
                    <div className="th">Juan Guzman</div>
                    <div className="th">Septiembre</div>
                    <div className="th">$500.00</div>
                    <div className="th"><input type="checkbox" className='checkBox'/></div>
                  </div>
                  <div className="row">
                    <div className="th">0010</div>
                    <div className="th">Juan Guzman</div>
                    <div className="th">Octubre</div>
                    <div className="th">$500.00</div>
                    <div className="th"><input type="checkbox" className='checkBox'/></div>
                  </div>
                  <div className="row">
                    <div className="th">0010</div>
                    <div className="th">Juan Guzman</div>
                    <div className="th">Noviembre</div>
                    <div className="th">$500.00</div>
                    <div className="th"><input type="checkbox" className='checkBox'/></div>
                  </div>
                  <div className="row">
                    <div className="th">0010</div>
                    <div className="th">Juan Guzman</div>
                    <div className="th">Diciembre</div>
                    <div className="th">$500.00</div>
                    <div className="th"><input type="checkbox" className='checkBox'/></div>
                  </div>
            </div>
          </div>
          <div className='Modal-container_pagar'>
            <div className='Modal-total'>
              <span>Total:</span>
              <input type="text" name="" id="" value="$500"/>
            </div>
            <button type='submit' className="Clientes-btnPay"><i class="fa-sharp fa-solid fa-plus"></i>Pagar</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalCobranza