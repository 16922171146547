import { useState} from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import querys from '../services/querys.js';
import { useNavigate } from 'react-router-dom';
import { DateTime} from 'luxon';

const NewCustomer = () => {
    const login = useSelector(state => state.login.login)
    const dispatch = useDispatch();
    const [form,setForm] = useState({});
    const navigate = useNavigate();


    const onChangeInput = ({ target }) => {
        const {name,value} = target;
        setForm({
          ...form,
          [name]:value
        })
      }

    const handleInsertCustomer = (e) => {
        const now = DateTime.now(); 
        e.preventDefault();
        const body = {
            'name': form.name,
            'lastname': form.lastname, 
            'rfc': form.rfc,  
            'curp': form.curp, 
            'email': form.email, 
            'phone': form.phone, 
            'razonsocial': form.razonsocial, 
            'regimenfiscal': form.regimenfiscal, 
            'cfdi': form.cfdi, 
            'noexterior': form.noexterior, 
            'nointerior': form.nointerior, 
            'codigopostal': form.codigopostal, 
            'colonia': form.colonia, 
            'ciudad': form.ciudad, 
            'localidad': form.localidad, 
            'estado': form.estado, 
            'plan': form.plan, 
            'calle': form.calle,
            'pais': form.pais,  
            'alta':now.toFormat('yyyy-MM-dd'),
            'active': 'true', 
            "password":"12345",  

        }

        querys.newCustomer(body,dispatch,login.token).then(response => {
           navigate(`/dashboard`)
        }).catch(error => {
            console.log('error',error)
        })
    }

  return (
    <form onSubmit={handleInsertCustomer}>
      <div className="Modal-formContainer">
        <div className="Modal-formColumn">
          <label htmlFor="" >Nombre:</label>
          <input type="text" pplaceholder='' onChange={onChangeInput}  name='name'/>
          <label htmlFor="" >Apellido:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='lastname'/>
          <label htmlFor="" >R.F.C:</label>
          <input type="text" pplaceholder='' onChange={onChangeInput}  name='rfc'/>
          <label htmlFor="" >C.U.R.P.:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='curp'/>
          <label htmlFor="" >EMAIL:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='email'/>
          <label htmlFor="" >CELULAR:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='phone'/>
          <label htmlFor="" >Razón social:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='razonsocial'/>
          <label htmlFor="" >Régimen Fiscal</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='regimenfiscal'/>
          <label htmlFor="" >Uso CFDI:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='cfdi'/> 
          <label htmlFor="" >No. Ext.:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='noexterior'/>
        </div>
        <div className="Modal-formColumn">
          <label htmlFor="" >No.Int.:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='nointerior'/>
          <label htmlFor="" >Código Postal:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='codigopostal'/>
          <label htmlFor="" >Colonia:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='colonia'/>
          <label htmlFor="" >Calle:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='calle'/>
          <label htmlFor="" >Ciudad:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='ciudad'/>
          <label htmlFor="" >Localidad:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='localidad'/>
          <label htmlFor="" >Estado:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='estado'/>
          <label htmlFor="" >Pais:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='pais'/>
          <label htmlFor="" >Paquete:</label>
          <input type="text" placeholder='' onChange={onChangeInput}  name='plan'/>
        </div>
      </div>
      <div className='Modal-containerBtn'>
          <input type="submit" value="Guardar" className='Modal-btnSave'/>
      </div>
    </form>
  )
}

export default NewCustomer