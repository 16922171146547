import { createSlice } from "@reduxjs/toolkit";

const customersSlice = createSlice({
    name:'customers',
    initialState:{
        loading:false,
        customers:[],
        error:false
    },
    reducers: {
        customersSuccess (state,action) {
            state.loading = false
            state.customers=action.payload
            state.error = false
        },
        customersError (state,action) {
            state.loading = false
            state.customers=[]
            state.error = action.payload
        },
        customersLoading (state,action) {
            state.loading = true
        }
    }
})

export const { customersSuccess,customersError,customersLoading } = customersSlice.actions
export default customersSlice.reducer;